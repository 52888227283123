@import "bootstrap";
@import "leaflet";
body#livemap {
    article,aside,details,figcaption,figure,footer,header,hgroup,hr,menu,nav,section{display:block}a,hr{padding:0}abbr,address,article,aside,audio,b,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,p,pre,q,samp,section,small,span,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,ul,var,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:0 0;font-weight:400}ins,mark{background-color:#ff9;color:#000}body{line-height:1}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}a{margin:0;font-size:100%;vertical-align:baseline;background:0 0}ins{text-decoration:none}mark{font-style:italic;font-weight:700}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{height:1px;border:0;border-top:1px solid #ccc;margin:1em 0}input,select{vertical-align:middle}
}

html.livemap-wrapper {
    width: 100%;
    height: 100%;

    /* Iframe styles */

    html,
    body,
    .map-big {
        height: 100%;
        width: 100%;
    }

    .map-big {
        position: relative;
        z-index: 1;
    }

    .maps {
        /*position: relative;*/
    }

    .map-cutout {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .map-small {
        border: solid 3px gray;
        border-width: 3px 0 1px 3px;
        bottom: 0;
        height: 768px;
        position: absolute;
        right: 0;
        width: 512px;
        z-index: 10;
    }

    .map-small.hidden {
        left: -9999em;
        right: auto;
    }

    @media (min-width: 1025px) {
        .gm-style .gm-style-iw {
            text-align: center;
        }

        .gm-style .gm-style-iw + div {
            display: none;
        }
    }
}

/* Brick styles */
$name: '.b-livemap';
#{$name} {
    margin: 50px;

    @include media-breakpoint-down(sm) {
        margin: 30px 0;
    }

    &__wrapper {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        &-inner {
            display: flex;
            flex-direction: column;
        }

        #{$name}__description {
            margin-left: 50%;
            color: #919191;
            padding: 0 5%;
            margin-bottom: -2.5%;
            font-size: 14px;
            line-height: 1.6;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                padding: 0 12.5%;
                margin-bottom: 1rem;
            }

            a {
                color: #05164d;
            }
        }

        #{$name}__headline {
            font-family: $font-family-base;
            font-weight: $font-weight-bold;
            font-style: normal;
            line-height: 1.4;
            font-size: 3.563em;
            margin: 0 0 1em;
            color: $white;

            @include media-breakpoint-down(md) {
                padding: 0 12.5%;
                color: #05164d;
                font-size: 2em;
                margin-bottom: .5rem;
            }
        }

        #{$name}__content-wrapper {
            display: flex;
            flex-direction: row;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            #{$name}__iframe {
                margin-bottom: 5%;
            }

            #{$name}__content {
                background-color: #05164d;
                display: flex;
                flex-direction: column;
                margin-top: 5%;
                padding: 60px;
                color: #fff;
                justify-content: center;

                #{$name}__copy {
                    font-size: 17px;
                    line-height: 1.6;
                }
            }

            #{$name}__iframe, #{$name}__content {
                aspect-ratio: 1/1;
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}