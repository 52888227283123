.sticky-icons__item {
    position: fixed;
    right: 0;
    top: 50svh;
    z-index: 11;
    display: flex;
    align-items: center;
    transform: translateX(calc(100% - 60px));
    transition: transform 0.5s;
    &.sticky-icons__item {
        &--yellow {
            background-color: $color-abm-overlay-yellow;
            a {
                color: $color-abm-overlay-blue;
            }
        }
        &--blue {
            background-color: $color-abm-overlay-blue;
            a {
                color: $color-abm-overlay-yellow;
            }
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        width: 60px;
        height: 60px;
        img {
            width: 30px;
        }
    }
    a {
        font-size: 18px;
        font-weight: $font-weight-bolder;
        display: block;
        padding: 5px 20px;
        text-decoration: none;
        width: fit-content;
        opacity: 0;
        transition: opacity 0.5s;
    }
    &--blue {
        top: calc(50svh + 70px);
    }
    &:hover {
        transform: translateX(0);
        a {
            opacity: 1;
        }
    }
}