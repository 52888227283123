$name: '.b-faq';

#{$name} {
    clear: both;

    h2 {
        @media screen and (max-width: 50em) {
            margin-bottom: 0.5rem;
            line-height: 1.4;
            margin-top: 0;
            padding: .7em 0;
            font-size: 2rem;
        }
    }

    @media screen and (min-width: 50em) {
        padding-left: 0;
        max-width: 100%;
    }

    &__wrapper {
        background-image: url('../../img/bus-side-mobile.jpg');
        background-repeat: no-repeat;
        background-position: 0 200px;

        @media screen and (min-width: 50em) {
            background-image: url('../../img/bus-side.jpg');
            background-repeat: no-repeat;
            background-position: 50% 430px;
        }
    }

    &-copytext {
        overflow: hidden;
        @media screen and (min-width: 50em) {
            margin: 0 -7px;
            height: auto
        }

        :nth-child(n+4) {
            display: none;
        }

        &.open {
            :nth-child(n+4) {
                display: block;
            }
        }
    }

    .show-for-medium {
        @media screen and (max-width: 50em) {
            display: none;
        }
    }

    .hide-for-medium {
        @media screen and (min-width: 50em) {
            display: none;
        }
    }

    @media screen and (max-width: 0em), screen and (min-width: 50em) {
        .show-for-small-only {
            display: none !important;
        }
    }

    .column, .columns {
        float: left;
    }

    &__flip-text {
        color: $primary;
        font-weight: $font-weight-medium;
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__flipped-text {
        width: 100%;
        height: 100%;
        position: relative;
        color: $primary;

        p {
            font-weight: 500;
            font-size: $font-size-15px;

            @include media-breakpoint-up(sm) {
                font-size: $font-size-18px;
            }
        }
    }

    .button {
        &.load-less, &.load-more {
            width: 100%;
            background: #ffad00;
            color: #05164D;
            border-radius: 4px;
            font-family: $font-family-base;
            font-weight: $font-weight-bold;
            margin: 30px 0 45px;

            &:hover, &:focus {
                background-color: #ffbd32;
            }
        }
    }

    .footnote {
        font-size: .938em;
        text-align: left;
        padding: 0;

        @media screen and (min-width: 50em) {
            padding: 1em 0 2em;
        }
    }
}

/**
 * Flip Container
 */

.flip-container {
    perspective: 1000px;
    transform-style: preserve-3d;
    margin-bottom: 15px;

    &:nth-child(2) {
        margin-bottom: 225px;
    }

    @media screen and (min-width: 50em) {
        padding: 0 7px;

        &.medium-6 {
            width: 50%;
        }

        &:nth-child(2) {
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 50em) {
        &.flipped:nth-child(2) {
            margin-bottom: 15px;
        }
    }
}

@media screen and (min-width: 50em) {
    .flip-container {
        padding: 0 7px
    }

    .flip-container.medium-6 {
        width: 50%
    }

    .flip-container:nth-child(2) {
        margin-bottom: 15px
    }
}

.flip-container.flipped .back {
    transform: rotateY(0)
}

.flip-container.flipped .front {
    transform: rotateY(180deg)
}

.back, .flipper, .front {
    transition: .6s;
    transform-style: preserve-3d
}

.flip-container, .front {
    width: 100%;
    height: 80px;
}

@media screen and (min-width: 50em) {
    .flip-container, .front {
        height: 130px
    }
}

.back {
    width: 100%;

    @media screen and (max-width: 50em) {
        height: auto;
    }
}

@media screen and (min-width: 50em) {
    .back {
        height: 130px
    }
}

.flip-container.double-height, .flip-container.double-height .back, .flip-container.double-height .front {
    @media screen and (min-width: 50em) {
        height: 275px
    }
}

.flipper {
    position: relative
}

.back, .flip-button, .front {
    position: absolute;
    cursor: pointer
}

.back, .front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    border: 2px solid #eaeaea;
    background: #fff;
    padding: 1em 30px 1em 1em;
    font-size: 15px;
    line-height: 1.2;
}

.back a::before, .front a::before {
    background-position: 5px .25em
}

.back a:hover, .front a:hover {
    color: #919191
}

.back a:hover::before, .front a:hover::before {
    background-image: url("data:image/svg+xml,%3Csvg id='Arrow' xmlns='http://www.w3.org/2000/svg' width='25' height='20' viewBox='0 0 25 20'%3E%3Ctitle%3Eico-arrow%3C/title%3E%3Cpolygon points='15.077 0 12.734 2.29 18.854 8.5 0 8.5 0 11.5 18.854 11.5 12.734 17.71 15.077 20 25 10 15.077 0' fill='%23919191'/%3E%3C/svg%3E")
}

.front {
    z-index: 2;
    transform: rotateY(0)
}

@media screen and (min-width: 50em) {
    .back, .front {
        border: 4px solid #eaeaea
    }

    .front {
        padding-right: 20%
    }

    .contact {
        padding-bottom: 3em
    }
}

.back {
    transform: rotateY(-180deg);
    padding-right: 0
}

.close-icon, .flip-button .flip-to-front {
    transform: rotate(45deg)
}

.back span {
    padding-right: 30px
}

.flip-button {
    bottom: 0;
    right: 0;
    height: 30px;
    width: 22px
}

.flip-button .flip-to-back, .flip-button .flip-to-front {
    background: #05164D;
    height: 17px;
    position: relative;
    width: 3px
}

.flip-button .flip-to-back::after, .flip-button .flip-to-front::after {
    background: #05164D;
    content: "";
    height: 3px;
    left: -7px;
    position: absolute;
    top: 7px;
    width: 17px
}

.hide {
    display: none !important;
}