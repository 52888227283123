@import "bootstrap";

$name: '.footer';

#{$name} {
    &__top {
        order: 2;
        padding-bottom: 30px !important;
        background-color: $dark-blue;
        #{$name}__logo-list {
            &--img {
                align-self: flex-end;
            }
            &--wrapper {
                position: relative;
                .tripadvisor-logo-holder {
                    position: absolute;
                    right: -150px;
                    bottom: 20px;
                    width: fit-content;
                    @include media-breakpoint-down(xxl) {
                        right: -80px;
                    }
                    @include media-breakpoint-between(md, xl) {
                        left: 50px;
                        bottom: 100px;
                    }
                    @include media-breakpoint-down(md) {
                        top: -150px;
                        right: 50px;
                    }
                    @include media-breakpoint-down(sm) {
                        right: 25px;
                    }

                    .tripadvisor-logo {
                        width: 105px;
                        height: auto;
                    }
                }
            }
        }
    }
    &__main {
        order: 1;
        padding-top: 60px !important;
        background-color: $dark-blue;
        * { color: $white; }
        #{$name}__columns {
            gap: 30px;
            #{$name}__column {
                &-two, &-three, &-four {
                    width: 25%;
                    #{$name}__column-headline {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    #{$name}__column-link {
                        text-decoration: none;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
                &-one {
                    width: 25%;
                    #{$name}__column-headline {
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1;
                    }
                }
            }
            #{$name}__contact-data > * {
                padding-top: 36px;
                font-size: 12px;
                @include media-breakpoint-down(sm) {
                    max-width: 200px;
                }
            }
        }
        #{$name}__social-links {
            padding-top: 20px;
            line-height: 1;
        }
    }
    &__bottom {
        order: 3;
        * { color: $dark-blue; }
        a { text-decoration: none }
    }
}