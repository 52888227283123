@font-face {
    font-family: 'Helvetica Neue';
    src: url("../fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.woff2") format('woff2');
    font-weight: 600;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url("../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.woff2") format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url("../fonts/HelveticaNeue-Regular/HelveticaNeue-Regular.woff2") format('woff2');
    font-weight: 400;
}

/* exo-300 - latin */
@font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 300;
    src: url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-300.svg#Exo') format('svg'); /* Legacy iOS */
}
/* exo-regular - latin */
@font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    src: url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-regular.svg#Exo') format('svg'); /* Legacy iOS */
}
/* exo-700 - latin */
@font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    src: url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../shared/fonts/exo-v20-latin/exo-v20-latin-700.svg#Exo') format('svg'); /* Legacy iOS */
}