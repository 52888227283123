@mixin make-font($size: null, $weight: null, $family: $font-family-base, $line-height: null, $color: null) {
    @if $color {
        color: $color
    }
    @if $size {
        font-size: $size
    }
    @if $weight {
        font-weight: $weight
    }
    @if $family {
        font-family: $family
    }
    @if $line-height {
        line-height: $line-height
    }
}

@mixin font-h2 {
    @include make-font(2em, $font-weight-bold, $font-family-base, 1, $color-abm-overlay-blue);
}