$name: '.b-pricing';

#{$name} {
    &__wrapper {
        @include media-breakpoint-down(sm) {
            padding: 1.6em 12.5%;
        }
        h2 {
            padding: 10px 0 15px;
            color: $dark-blue;
            font-size: 3.563em;
            font-weight: $font-weight-bolder;
            margin-bottom: 8px;
            @include media-breakpoint-down(sm) {
                font-size: 2em;
            }
        }
        h3 {
            color: #333;
            font-size: 1.25em;
            padding-top: 1.6em;
            padding-bottom: 5px;
            clear: both;
            @include media-breakpoint-up(md) {
                margin-top: 0;
                clear: both;
                line-height: 1;
            }
        }
        #{$name}__header {
            position: relative;
            #{$name}__text {
                padding-left: 0;
                font-size: .938em;
                color: $grey-dark;
                line-height: 24px;
                @include media-breakpoint-up(md) {
                    position: relative;
                    margin-bottom: -14em;
                    padding-right: 85px;
                }
            }
            #{$name}__image {
                position: absolute;
                bottom: 0;
            }
        }
        #{$name}__adult-prices {
            padding: 1em 0;
            border-bottom: 1px solid $grey-300;
            position: relative;
            #{$name}__price-type {
                margin: 0;
                padding: 0;
                @include media-breakpoint-up(md) {
                    line-height: 1;
                    border-right: 1px solid $grey-300;
                }
            }
            #{$name}__price-type:first-child {
                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    margin-top: 1em;
                    padding-bottom: 1em;
                    #{$name}__price-type--onboard {
                        margin: 0;
                    }
                }
            }
            #{$name}__price-type:last-child {
                @include media-breakpoint-up(md) {
                    padding-bottom: 0;
                    margin-bottom: 1em;
                }
            }
        }
        #{$name}__child-prices {
            border-bottom: 1px solid $grey-300;
            position: relative;
            padding: 1em 0 50px 0;
            margin-top: 16px;
            @include media-breakpoint-up(md) {
                padding-left: 10%;
                margin-top: 0;
            }
            &--info {
                position: relative;
                p {
                    color: $color-abm-brand-grey;
                    font-size: .875em;
                    padding: .4em 0;
                    @include media-breakpoint-up(md) {
                        right: 0;
                        margin-top: 6px;
                        font-size: .813em;
                    }
                }
            }
        }
        #{$name}__price-type--title {
            color: $color-abm-overlay-blue;
            font-size: .938em;
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                font-size: 1.063em;
                margin: 0;
            }
        }
        #{$name}__price-type--price {
            color: $color-abm-overlay-blue;
            font-size: 2em;
            margin-bottom: 0;
            font-weight: $font-weight-bolder;
            line-height: 1.3;
            @include media-breakpoint-up(md) {
                font-size: 3.3em;
                line-height: 1;
                margin-bottom: .1em;
            }
        }
        #{$name}__price-type--onboard {
            color: $color-abm-brand-grey;
            font-size: .875em;
            line-height: 22px;
        }
        .discounted-prices {
            margin-bottom: 2em;
            padding: 1em 0;
            border-bottom: 1px solid $grey-300;
        }
        #{$name}__child-prices--info {
            position: relative;
            p {
                color: $color-abm-brand-grey;
                font-size: .875em;
                padding: .4em 0;
                @include media-breakpoint-up(md) {
                    margin-top: 6px;
                    font-size: .813em;
                }
                @include media-breakpoint-down(sm) {
                    position: absolute;
                    bottom: 0;
                    text-align: end;
                }
            }
            @include media-breakpoint-down(sm) {
                width: 200px;
            }
        }
    }
    .buy-tickets {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $grey-200;
        color: $color-abm-overlay-blue;
        text-align: center;
        margin: 0;
        padding: 1.6em 12.5%;
        @include media-breakpoint-up(md) {
            margin-top: 40px;
            padding: 5em 12.5%;
            flex-direction: row;
        }
        h3 {
            padding-left: 100px;
            @include media-breakpoint-down(sm) {
                padding: 0;
                font-size: 1.1875rem;
                margin-bottom: 10px;
            }
        }
        &__buttons {
            font-weight: 700;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-left: 20px;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                margin: 0;
                gap: 15px;
            }
            &-item {
                display: flex;
                align-items: center;
                padding: 5px 20px;
                border-radius: 4px;
                width: fit-content;
                height: fit-content;
                gap: 15px;
                text-decoration: none;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    justify-content: center;
                }
                .icon {
                    display: flex;
                    img {
                        width: 18px;
                    }
                }
            }
            &-item:first-child {
                background-color: $color-abm-overlay-yellow;
                color: $color-abm-overlay-blue;
            }
            &-item:last-child {
                background-color: $color-abm-overlay-blue;
                color: $color-abm-overlay-yellow;
            }
            &-item:first-child:hover {
                filter: brightness(90%);
            }
            &-item:last-child:hover {
                filter: brightness(90%);
            }
        }
    }
    &__disclaimer {
        position: relative;
        p {
            color: $color-abm-brand-grey;
            font-size: .875em;
            padding: .4em 0;
        }
    }
}