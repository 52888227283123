/**
 * Bootstrap Overrides
 */
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$white: white;
$black: black;
$grey: #6f6f6f;
$grey-dark: #5f5f5f;
$grey-200: #f5f5f5;
$grey-300: #eaeaea;
$dark-blue: #05174D;

//@import "../fonts/fonts";

$font-family-base: 'Helvetica Neue', Fallback, sans-serif;
$fontfamily-fontawesome-brands: 'Font Awesome 5 Brands';
$color-abm-overlay-blue: #05164D;
$color-abm-overlay-blue-lighter: #1583cc;
$color-abm-overlay-yellow: #ffad00;
$color-abm-brand-blue: #05164c;
$color-abm-brand-grey: #919191;
$color-white: white;
$color-black: black;
$color-grey: #6f6f6f;
$color-grey-02: #f5f5f5;
$color-grey-03: #eaeaea;
$color-grey-04: #dddddd;
$color-red: #a00006;
$color-green: #45b812;

$default-space-bus-display: 6px;

$primary: $color-abm-overlay-blue;

$screen-xs-max: 767px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$font-size-12px: 0.75rem;
$font-size-16px: 1rem;
$font-size-15px: 0.9275rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-26px: 1.5625rem;
$font-size-30px: 1.875rem;

$line-height-16px: 1rem;
$line-height-24px: 1.5rem;
$line-height-30px: 1.875rem;

$default-space: 15px;

.btn {
    text-align: center;
    vertical-align: middle;
}

.btn-primary {
    border-radius: 0;
    background-color: $color-abm-overlay-yellow;
    text-transform: uppercase;
    color: #fff;
    border: none;
    transition: all .3s ease-in-out;
    font-weight: 700;
    padding: 17px 25px;
}
/*
.booking-btn {
    background: url(/static/src/abm/img/ticket-icon-blue.svg) 15px 12px no-repeat $color-abm-overlay-yellow !important;
    background-size: 19px auto!important;
    padding: 10px 10px 10px 45px !important;
    font-size: .9rem !important;
    margin-right: 10px;
    @media screen and (min-width: $screen-md-min) {
        text-align: left !important;
    }

    &:hover {
        background: url(/static/src/abm/img/ticket-icon-blue.svg) 15px 12px no-repeat lighten($color-abm-overlay-yellow,5%) !important;
        background-size: 19px auto !important;
    }

    &--secondary {
        color: $color-white !important;
        background: url(/static/src/abm/img/germany-map-icon-white.svg) 15px 5px no-repeat $color-grey !important;
        background-size: 19px auto !important;

        &:hover {
            color: $color-white !important;
            background: url(/static/src/abm/img/germany-map-icon-white.svg) 15px 5px no-repeat lighten($color-grey,5%) !important;
            background-size: 19px auto !important;
        }
    }
}*/

.pop-up{
    z-index: 240;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba($color-black,0.5);
    &__content{
        position: absolute;
        width: 767px;
        max-width: 90vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &--wrapper{
            position: relative;
            height: 100vh;
        }
    }
    &__inner{
        padding: 40px 50px 50px 50px;
        background-color: $color-white;
        min-height: 250px;
        position: relative;
        &::after{
            content: '';
            display: block;
            position: absolute;
            bottom: -20px;
            width: 0;
            height: 0;
            left: 50%;
            transform: translate(-50%,0);
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $color-white;
            z-index: 1;
        }
    }
    &__image{
        margin-bottom: 30px;
    }
    &__headline{
        margin-bottom: 30px;
    }
    &__footer{
        background-color: $color-abm-overlay-blue-lighter;
        min-height: 160px;
        position: relative;
    }
    &__close-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

    }
}

.fixed-overlay {
    display: block;
    transition: opacity .2s ease-in;

    &.closed {
        opacity: 0 !important;
    }

    @media screen and (max-width: $screen-xs-max) {
        display: none;
    }

    position: fixed;
    left: 0;
    bottom: 230px;
    z-index: 999;

    .close-button {
        cursor: pointer;
        position: absolute;
        z-index: 9999;
        right: -30px;
        top: 15px;
        height: 30px;
        width: 30px;
    }
}

.fixed-overlay--mobile {
    display: none;

    @media screen and (max-width: $screen-xs-max) {
        display: block;
    }

    bottom: 0;
    left: 0;
    width: 100%;

    .overlay-image {
        width: 100%;
    }

    .close-button {
        right: auto;
        left: 5px;
        top: -30px;
        height: 40px;
        width: 40px;
    }
}

.d-none {
    display: none;
}

.pop-up{
    font-family: $font-family-base;
    &__footer{
        background-color: $color-abm-overlay-blue;
        min-height: 160px;
        position: relative;
    }
    #popUpOverlayCloseButton {
        background-color: $color-abm-overlay-yellow;
        border-color: $color-white;
        &:hover {
            background-color: $color-abm-overlay-blue-lighter;
        }
    }
}

.top-section {
    .booking-btn {
        min-width: 190px;
        text-align: center !important;
    }
    .copytext__footer {
        a {
            display: inline-block;
            margin-right: 10px;
        }
    }
}

.sticky-menu {
    .ticket-icon {
        height: 20px;
    }

    .booking-btn {
        background: 0 0 !important;
        padding: 0 !important;
    }
}

.menu-medium-trigger {
    .ticket-icon {
        height: 20px;
    }
    .booking-btn {
        background: 0 0 !important;
        padding: 0 !important;
        bottom: 60px;

        &--secondary {
            bottom: 20px;
        }
    }
}

.prices {
    .discounted-prices {
        @media screen and (min-width: $screen-xs-max) {
            padding: 1em 0 0;
            margin: 0 0 1em 0;
        }

        &:not(:last-child) {
            @media screen and (min-width: $screen-xs-max) {
                border-bottom: 1px solid $color-grey-03;
            }
        }
    }

    .buy-tickets h3 {
        @media screen and (max-width: $screen-lg-min) {
            display: block;
            margin-left: 0;
            margin-right: 0;
        }
    }
}


#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: #cdcdcd!important;
    border-color: #cdcdcd!important;
}

//@import "layout/elements";
