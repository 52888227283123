.header-stage {
    width: 100%;
    max-width: 1920px;
    margin: 100px auto 0;
    height: 800px;

    @include media-breakpoint-down(xl) {
        height: 650px;
    }

    @include media-breakpoint-down(sm) {
        height: 500px;
    }
    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        &-image {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &-content {
            position: absolute;
            bottom: 15%;
            left: 15%;
            color: $white;

            @include media-breakpoint-down(lg) {
                bottom: 10%;
                left: 10%;
            }

            @include media-breakpoint-down(sm) {
                bottom: 5%;
                left: 5%;
            }

            &-headline {
                font-weight: $font-weight-bold;
                font-size: 90px;
                max-width: 50%;
                line-height: 1.25;
                margin-bottom: 30px;

                @include media-breakpoint-down(xxl) {
                    font-size: 70px;
                    margin-bottom: 20px;
                    line-height: 1;
                }
                @include media-breakpoint-down(sm) {
                    max-width: 80%;
                    font-size: 40px;
                }
            }
            &-description {
                font-size: $font-size-16px;
                max-width: 40%;

                @include media-breakpoint-down(sm) {
                    max-width: 70%;
                }
            }
            &-buttons {
                font-weight: $font-weight-bold;
                font-size: $font-size-16px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-top: 30px;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }

                &-item {
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    border-radius: 4px;
                    width: fit-content;
                    height: fit-content;
                    gap: 15px;
                    text-decoration: none;
                    .icon {
                        display: flex;
                        img {
                            width: 18px;
                        }
                    }

                }
                &-item:first-child {
                    background-color: $color-abm-overlay-yellow;
                        color: $color-abm-overlay-blue;
                }
                &-item:last-child {
                    background-color: $color-abm-overlay-blue;
                        color: $color-abm-overlay-yellow;
                }
                &-item:first-child:hover {
                    filter: brightness(90%);
                }
                &-item:last-child:hover {
                    filter: brightness(90%);
                }
            }
        }
    }
}