$name: '.b-driving-schedule';

#{$name} {
    .container.editmode {
        h2 {
            padding: 0;
        }
    }

    &__wrapper {
        padding-top: 40px;
        margin-top: -40px;
    }

    &__wrapper h2 {
        padding: 0;
        margin: 0
    }

    &__copytext {
        font-size: 1.063em;
        line-height: 1.4rem;
        color: $color-abm-brand-grey;
        font-weight: 500;
        &--textarea {
            color: #8A091F;
        }
    }

    &__route.route-one {
        padding-bottom: 2em;
        margin-bottom: 2em;
    }

    &__link {
        color: $color-abm-overlay-blue;
        font-weight: 500;
        display: inline-block;
        padding-left: 1.4em;
        position: relative;
        text-decoration: none;
        margin-bottom: 1rem;
        &:hover {
            color: #666;
            &:before {
                background-image: url('../../img/download-hover.svg');
            }
        }
        &:before {
            background-image: url('../../img/download.svg');
            background-position: 5px 0.5em;
            background-repeat: no-repeat;
            background-size: 0.75em auto;
            content: "";
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            height: 100%;
            left: 0;
            position: absolute;
            width: 1.3em;
        }
    }

    &__route h3 {
        color: $color-abm-overlay-blue;
    }

    .route-title {
        font-size: .938em;
    }

    .frequency.hide-for-medium {
        @media (min-width: 768px) {
            display: none;
        }
    }

    .frequency.show-for-medium {
        @media (max-width: 768px) {
            display: none;
        }
    }

    @media (min-width: 768px) {
        &__wrapper {
            padding-top: 0;
            margin-top: 0;
        }

        &__wrapper h2 {
            padding-top: 130px;
            margin-top: -130px;
        }

        .route-title {
            font-size: 25px;
            margin-right: .5em;
        }
    }

    .frequency {
        display: block;
        font-size: .813em;
        color: $color-abm-brand-grey;
        margin: 1em 0 .5em;
    }

    .frequency .clock-icon {
        height: 1.1em;
        vertical-align: sub;
    }

    @media screen and (min-width: 50em) {
        .frequency {
            display: inline;
            font-size: 25px;
        }

        .frequency .clock-icon {
            height: 1em;
        }
    }

    .driving-time {
        font-size: .7em;
        font-weight: 500;
    }

    .timetable {
        list-style: none;
        margin-bottom: 24px;
        margin-top: 2em;
        text-align: center;
    }

    .timetable img {
        display: none;
    }

    @media screen and (min-width: 50em) {
        .driving-time {
            font-size: 1em;
        }

        .timetable {
            margin: 2em -5px 31px;
            background: linear-gradient(to left, #fff 50%, $color-abm-brand-grey 50%);
            background-size: 20px 3px;
            background-repeat: repeat-x;
            background-position: 0 11px;
        }

        .timetable img {
            display: block;
        }
    }

    .timetable .slick-track {
        background: linear-gradient(to left, #fff 50%, $color-abm-brand-grey 50%);
        background-size: 20px 3px;
        background-repeat: repeat-x;
        background-position: 0 6px;
    }

    .timetable .slick-next, .timetable .slick-prev {
        position: absolute;
        height: 100%;
        border: 0;
        padding: 0 10px;
    }

    .timetable .slick-next::before, .timetable .slick-prev::before {
        content: "";
        position: relative;
        display: block;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        margin-right: 10px;
    }

    .timetable .slick-prev {
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
        z-index: 1;
    }

    .timetable .slick-prev::before {
        border-left: 2px solid $color-abm-brand-grey;
        border-bottom: 2px solid $color-abm-brand-grey;
        float: left;
        margin-left: -30px;
    }

    .timetable .slick-next {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
        top: 0;
        right: 0;
    }

    .timetable .slick-next::before {
        border-right: 2px solid $color-abm-brand-grey;
        border-top: 2px solid $color-abm-brand-grey;
        float: right;
        margin-right: -30px;
    }

    .busstop {
        margin-right: 30px;
        text-align: center;
        font-weight: 500;
    }

    .busstop:first-child {
        background: linear-gradient(to right, #fff 50%, transparent 50%);
    }

    .busstop:last-child {
        background: linear-gradient(to left, #fff 50%, transparent 50%);
    }

    .busstop.columns {
        padding: 0;
    }

    @media screen and (min-width: 50em) {
        .busstop {
            display: inline-block;
            margin: 5px 0 0;
        }

        .busstop.col-2 {
            padding: 5px;
        }
    }

    .busstop.columns.slick-slide {
        width: auto;
    }

    .busstop.columns.slick-slide:last-child {
        float: left;
    }

    .busstop.columns.slick-slide:last-child::before {
        content: "";
        position: absolute;
        width: 500px;
        height: 10px;
        background: #fff;
    }

    .busstop h4 {
        font-size: .938em;
        margin-bottom: 1em;
        color: $color-abm-overlay-blue;
    }

    .busstop p {
        font-size: .813em;
        color: $color-abm-brand-grey;
        margin: 0;
    }

    .busstop .stop-icon {
        height: 15px;
        width: 15px;
        background: #ffad00;
        border: 3px solid #ffad00;
        border-radius: 50%;
        margin: 0 auto 2em;
        position: relative;
        color: #fff;
    }
    .busstop .stop-icon .stop-info-tooltip.no-times {
        top: -261px;
    }

    .busstop .stop-icon .stop-info-tooltip {
        visibility: hidden;
        opacity: 0;
        color: #fff;
        text-align: center;
        padding: 0 0 30px;
        position: absolute;
        z-index: 11;
        top: -365px;

        left: -145px;
        margin-left: -20px;
        transition: opacity .5s ease 0s;
        &.no-rides {
            top: -265px;
        }
    }

    .busstop .stop-icon .stop-info-tooltip::after {
        content: "";
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top: 10px solid $color-abm-overlay-blue;
        position: absolute;
        bottom: 10px;
        left: 50%;
        margin-left: -10px;
    }

    .busstop .stop-icon .stop-info-tooltip .next-buses {
        background: $color-abm-overlay-blue;
        padding-top: 5px;
    }

    .busstop .stop-icon .stop-info-tooltip p {
        color: #fff;
        font-size: 1em;
        margin-bottom: 0;
    }

    .busstop .stop-icon .stop-info-tooltip p img {
        height: 1.1em;
        display: inline;
    }

    .busstop .stop-icon .stop-info-tooltip img {
        max-width: 300px;
    }

    .busstop .stop-icon .stop-info-tooltip span {
        margin: 0 12px;
        line-height: 3em;
    }

    .busstop .stop-icon .stop-info-tooltip a {
        color: $color-abm-brand-grey;
        font-size: .813em;
        text-decoration: none;
        line-height: 4em;
        background: $color-abm-overlay-blue;
        display: block;
    }

    .busstop .stop-icon .stop-info-tooltip a .link-icon {
        height: 1.15em;
        margin: 0;
    }

    .busstop .stop-icon .stop-info-tooltip a:hover {
        color: #fff;
    }

    .busstop:hover .stop-info-tooltip {
        visibility: visible;
        opacity: 1;
        margin-left: 0;
    }

    .busstop:hover .stop-icon {
        background: #ffad00;
    }

    .clearfix:before, .clearfix:after {
        content: " ";
        display: table;
    }
}
