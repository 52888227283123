/* === Text Mixins === */

@mixin text-uppercase {
    text-transform: uppercase;
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal $padding-vertical;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// quadratic images
@mixin quadratic-image($max-size: 100%) {
    padding-top: 100%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        max-width: $max-size;
        max-height: $max-size;
    }
}

// transforms
@mixin transform($transform) {
    -webkit-transform: $transform;
    -ms-transform: $transform; // IE9 only
    -o-transform: $transform;
    transform: $transform;
}

@mixin translate-rotate($x, $y, $degrees) {
    -webkit-transform: translate($x, $y) rotate($degrees);
    -ms-transform: translate($x, $y) rotate($degrees); // IE9 only
    -o-transform: translate($x, $y) rotate($degrees);
    transform: translate($x, $y) rotate($degrees);
}

// browser support flexbox
@mixin flex-parent($flex-flow: row wrap, $align-items: stretch, $justify-content: space-between) {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */

    -webkit-flex-flow: $flex-flow;
    -moz-flex-flow: $flex-flow;
    -ms-flex-flow: $flex-flow;
    flex-flow: $flex-flow;

    -webkit-align-items: $align-items;
    -moz-align-items: $align-items;
    -ms-align-items: $align-items;
    align-items: $align-items;

    -webkit-justify-content: $justify-content;
    -moz-justify-content: $justify-content;
    -ms-justify-content: $justify-content;
    justify-content: $justify-content;
}

@mixin flex-child() {

}

// Icon
@mixin icon-fill($fill: #ffffff) {
    fill: $fill;
}

@mixin icon($size, $fill: null) {
    @if $size == tiny {
        width: 10px;
        height: 10px;
    } @else if $size == small {
        width: 16px;
        height: 16px;
    } @else {
        width: $size;
        height: $size;
    }
    @if $fill {
        @include icon-fill($fill);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

@mixin background-color($background-color: $white, $selector: white) {
    background-color: $background-color;
    &.bg-#{$selector}--transparent {
        background-color: rgba($background-color, 0.9);
    }
}

/* === BEM Mixins === */

/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin element($element, $more: null) {
    #{bemEls($element, $more)} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier, $more: null) {
    #{bemMods($modifier, $more)} {
        @content;
    }
}

/// @alias element
@mixin e($element, $more: null) {
    @include element($element, $more)  {
        @content;
    }
}

/// @alias modifier
@mixin m($modifier, $more: null) {
    @include modifier($modifier, $more) {
        @content;
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function bemEl($name) {
    @return '&__#{$name}';
}

@function bemEls($firstName, $moreNames: null) {
    $namesStr: '#{bemEl($firstName)}';

    @if $moreNames {
        @each $name in $moreNames {
            $namesStr: "#{$namesStr}, #{bemEl($name)}";
        }
    }

    @return $namesStr;
}

@function bemMod($name) {
    @return '&--#{$name}';
}

@function bemMods($firstName, $moreNames: null) {
    $namesStr: '#{bemMod($firstName)}';

    @if $moreNames {
        @each $name in $moreNames {
            $namesStr: "#{$namesStr}, #{bemMod($name)}";
        }
    }

    @return $namesStr;
}