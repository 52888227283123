/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/
body {
  font-family: $font-family-base;
}


h2, .h2 {
    @include font-h2();
}

.border-t-radius-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.border-b-radius-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@include media-breakpoint-up(md) {
    .border-t-radius-md-3 {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .border-b-radius-md-3 {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

