.b-wysiwyg {
    &__headline {
        color: $color-abm-overlay-blue;
        font-size: 3.563em;
        font-weight: $font-weight-bolder;
        padding: 1.4em 0;
    }
    &__text {
        font-size: 1em;
        line-height: 1.6;
        color: $color-abm-brand-grey;
    }
}