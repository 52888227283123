.b-text-media {
    &__wrapper {
        margin: 120px auto 0;
        max-width: 1300px;
        padding-left: 300px;
        padding-top: 16px;
        @include media-breakpoint-down(xl) {
            padding: 16px 9.375% 0;
        }

        @include media-breakpoint-down(md) {
            padding: 1.6em 12.5%;
        }

        .content {
            margin: 0;
            padding: 0;

            h3 {
                margin-bottom: 14px;
                font-size: 2rem;
                color: $dark-blue;

                @include media-breakpoint-down(md) {
                    font-size: 1.1875rem;
                }
            }

            .wysiwyg-text {
                padding-right: $default-space;
                p {
                    font-size: 16px;
                    font-weight: $font-weight-medium;
                    color: $grey-dark;
                }
            }
        }
    }
}