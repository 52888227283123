header {
    position: fixed;
    font-size: 20px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: $white;
    padding: 24px 48px;
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
        padding: 12px 5px;
    }

    @include media-breakpoint-down(xl) {
        font-size: 16px;
    }

    @include hamburger-static;
    @include hamburger-sticky;

    &.sticky, &.always-sticky {
        background-color: $dark-blue;
        padding: $default-space 0;
        @include transition(all 500ms);
        @include box-shadow(0 5px 10px 0 rgba($grey-200, 0.5));

        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        width: 40px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        background-color: $dark-blue;
        @include transition(background-color 500ms);
    }

    .header-wrapper {
        margin: 4px;
        div {
            margin: auto 0;
        }
    }

    nav.main-navigation {
        margin: auto 10px;
        ul {
            display: flex;
            list-style: none;
            padding-left: 0;
            text-align: center;
            justify-content: end;
            margin: auto 0;
            li {
                display: inline-block;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                margin: auto 2em;

                a {
                    color: $dark-blue;
                    text-decoration: none;
                }
                a::after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background: $color-abm-overlay-yellow;
                    transition: width 0.3s;
                    margin: 0 auto;
                }
                a:hover::after {
                    width: 100%;
                }

                &:last-child { padding-right: 0; }
                &:first-child { padding-left: 0; }

                @include media-breakpoint-between(sm, md) {
                    font-size: 0.93em;
                    padding: $default-space 12px;
                }
            }
        }
    }

    .language-switch {
        background-color: $white;
        color: $dark-blue;
        align-content: flex-end;

        ul {
            list-style: none;
            margin: 0;
            display: inline-block;
            text-transform: uppercase;
            font-weight: $font-weight-bold;

            padding-left: 0;


            li {
                display: inline-block;
                padding: 0 5px;
                width: 20px;
                line-height: 1;

                + li {
                    border-left: 1px solid $dark-blue;
                }

                a, span {
                    text-decoration-line: none;
                    color: $dark-blue;
                    @include transition(all 500ms);
                }
                a {
                    font-weight: 300;
                    &:hover, &:focus {
                        font-weight: 500;
                    }
                }

                &:last-child { padding-right: 0; }
                &:first-child { padding-left: 0; margin-right: 5px; }

                @include media-breakpoint-between(md, lg) {
                    font-size: 0.93em;
                }
            }
        }
    }

    #mobile-navigation {
        display: none;
    }
}

#mobile-navigation {
    .mm-navbars-bottom {
        .socialShareBar__item-text {
            display: none;
        }
    }

    .mm-navbar {
        .language-switch {
            width: 100%;
            display: inline-block;
            ul {
                display: inline-block;
                list-style: none;
                padding: 0;
                li {
                    float: left;
                    padding: 0 5px;
                    border-right: 1px solid $dark-blue;
                    color: $dark-blue;
                    &:last-child {
                        border-right: 0;
                    }
                    a, span {
                        font-size: 20px;
                    }
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}