
.b-bus-schedule {
    color: $dark-blue;

    span.icon {
        display: inline-block;

        svg {
            margin-top: -3px;
        }
    }

    h3.b-bus-schedule__headline {
        font-size: $font-size-25px;
        line-height: $line-height-30px;
        font-weight: $font-weight-medium;

        span.icon {
            svg {
                width: 21px;
                height: 21px;
            }
        }
    }

    .bus-stop {
        position: relative;
        padding-bottom: $grid-gutter-width*1.5;

        &--extended-padding {
            padding-bottom: $grid-gutter-width*3;
        }

        &__container {
            margin-left: $grid-gutter-width;

            @include media-breakpoint-up(md) {
                margin-left: $grid-gutter-width*1.5;
            }
        }

        &__toggle-departure-times {
            display: inline-block;
            font-size: $font-size-16px;
            color: $dark-blue;
            font-weight: $font-weight-medium;
            outline: none;
            border: none;
            word-break: keep-all;
            margin: 0;
            padding: 0;
            text-decoration: none;
            cursor: pointer;

            .icon {
                svg {
                    width: 14px;
                    height: 14px;
                    margin-top: -3px;
                    fill: $dark-blue;
                }
            }
        }

        &__details {
            max-width: 330px;
            background: $color-grey-02;
            border: 1px solid $color-grey-03;
            border-radius: 3px;
            margin: $grid-gutter-width/2 0 0 0;
            padding: $grid-gutter-width/2;

            overflow: hidden;
            transition:max-height 0.3s ease-out; // note that we're transitioning max-height, not height!
            height:auto;
            max-height:600px;

            &.collapse {
                max-height: 0;
            }
        }

        &__name {
            font-size: $font-size-20px;
            line-height: $line-height-24px;
            font-weight: $font-weight-medium;
            color: $dark-blue;
            display: block;
        }

        &__type {
            font-size: $font-size-16px;
            line-height: $line-height-20px;
            font-weight: $font-weight-medium;
            color: $grey-dark;
        }

        &__next-departures-label {
            font-size: $font-size-16px;
            line-height: $line-height-20px;
            font-weight: $font-weight-medium;
            display: block;
        }

        .departure-times-list {
            display: block;
            &__item {
                margin-right: $grid-gutter-width/4;
                display: inline-block;
                font-size: $font-size-20px;
                font-weight: $font-weight-regular;
                line-height: $line-height-24px;

                &--next {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &__departure-info {
            font-size: $font-size-16px;
            font-weight: $font-weight-light;
            line-height: $line-height-20px;
            color: $grey-dark;
            display: inline-block;
        }

        &__image {
            border: 1px solid $color-grey-04;
            border-radius: 3px;
        }

        &__maps-link {
            display: inline-block;
            text-decoration: none;

            span.icon {
                svg {
                    width: 14px;
                    height: 14px;
                    fill: $dark-blue;
                    margin-top: -3px;
                }
            }
        }
    }

    &__box {
        position: relative;
        padding: $grid-gutter-width;
        border: 1px solid $color-grey-03;
        border-radius: 3px;

        &--border-b {
            border-bottom: 0;
        }

        &--border-t {
            border-bottom: 0;
        }

        &--tight {
            padding: 0;
        }

        .stop-name {
            color: $dark-blue;
        }

        .stop-type {
            color: $grey-dark;
        }

        .departure-times-label {
            color: $dark-blue;
        }

        .btn {
            &--schedule-download-link {
                span.icon {
                    svg {
                        width: 16px;
                        height: 16px;
                        color: $dark-blue;
                    }
                }
            }
        }

        .stop-illustration {
            position: absolute;
            width: 30px;
            left: 0;
            top: 0;
            bottom: 0;
            flex-grow: 1;
            height: 100%; /* Adjust as needed */

            &__line {
                width: 2px;
                height: 100%;
                margin: 0 7px;
                background-color: $dark-blue;

                &--short {
                    height: 5px;

                }

                &--dashed-transition {
                    height: calc(100% - 100px);
                }
            }
            &__circle {
                height: 16px; /* Circle size */
                width: 16px;
                background-color: $color-white;
                border: 3px solid $dark-blue;
                border-radius: 50%;
                position: relative;
            }
            &__dashed-container {
                bottom: 0;
                height: 100px;
            }
            &__dashed {
                height: 31px;
                border-left: 2px dashed navy;
                margin: 4px 7px 0 7px; /* Space for arrow */
            }
            &__chevron-down {
                height: 20px;
                margin: -6px 0 0 -2px;

                .icon {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $dark-blue;
                    }
                }
            }
        }
    }

    .headline-wrapper {
        h2 {
            padding: 1em 0 0.7em;
        }
    }
}
