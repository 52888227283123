$name: '.b-benefit';

#{$name} {
    background: #f3f0ea;

    @include media-breakpoint-up(md) {
        max-width: 100%;
    }

    #{$name}__wrapper {
        padding: 2.6em 0;

        @include media-breakpoint-up(md) {
            padding: 0 9.375%;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    &__headline {
        margin-bottom: .5em;
        padding: 0 12.5%;
        font-size: 3.563em;
        font-weight: 600;
        color: $color-abm-overlay-blue;

        @include media-breakpoint-up(md) {
            margin-bottom: .1em;
            margin-top: 0;
            padding: 1.4em 0 0;
        }
    }

    &__subline {
        margin-bottom: 2.6em;
        padding: 0 12.5%;
        font-size: 1em;
        font-weight: 600;
        color: $color-abm-overlay-blue;

        @include media-breakpoint-up(md) {
            margin-bottom: 2em;
            padding: 0;
            font-size: 1em;
        }
    }

    #{$name}__slider {
        #{$name}__list {
            @include media-breakpoint-up(md) {
                margin: 0 -5px;
                display: flex;
                flex-wrap: wrap;
            }

            &-item {
                @include media-breakpoint-up(md) {
                    padding: 0 5px;
                    display: block;
                    margin-right: 0;
                    width: 33%;
                }

                &--img > picture > img {
                    width: 100%;
                    object-fit: cover;
                }

                &--subline {
                    height: fit-content;

                    > h4 {
                        color: $color-abm-overlay-blue;
                        margin: 2em 0 0 0;
                        height: 100%;
                        padding: 0 12.5%;
                        font-size: 14px;

                        @include media-breakpoint-up(md) {
                            padding: 1em 1em 0 0;
                            height: 100%;
                            margin: 1em 0 2em 0;
                            font-size: 20px;
                        }
                    }
                }

            }
        }
    }

    #{$name}__footnote {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
            color: $grey-dark;
            font-size: 13px;
            clear: both;
            text-align: right;
            padding: 0 0 30px;
        }
    }

    .carousel-control {
        &-prev, &-next {
            display: block;
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
}
