.button {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color .25s ease-out, color .25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0;
    padding: .85em 1em;
    margin: 0 0 1rem;
    font-size: .9rem;
    background-color: #2199e8;
    color: #fefefe;
}

.btn {
    &--yellow {
        font-weight: $font-weight-bold;
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        background-color: $yellow;
        color: $dark-blue;

        &:hover {
            background-color: darken($yellow, 5%);
        }

        &:active, &:focus {
            background-color: darken($yellow, 10%);
        }

        .icon {
            margin: 0 $grid-gutter-width/4;
        }
    }
}
