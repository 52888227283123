h1, .h1 {

}

h2, .h2 {
    margin-bottom: 0.5rem;
    line-height: 1.4;
    margin-top: 0;
    padding: .7em 0;
    font-size: 2rem;

    @include media-breakpoint-up(sm) {
        font-size: 3.563rem;
        padding: 2em 0 1.4em;
    }
}

.color {
    &--grey {
        color: $color-abm-brand-grey;

        span.icon {
            svg {
                fill: $color-abm-brand-grey;
            }
        }
    }

    &--blue {
        color: $dark-blue;

        span.icon {
            svg {
                fill: $dark-blue;
            }
        }
    }

    &--dark-grey {
        color: $grey-dark;

        span.icon {
            svg {
                fill: $grey-dark;
            }
        }
    }
}
